import React from 'react';
import Altitude from './Altitude';
import SpeedByHour from './SpeedByHour';
import SpeedByWeekday from './SpeedByWeekday';
import './styles.css';

function MyComponent({showing}) {

	return (
		<div className={`Charts`}>
			<SpeedByHour/>
			<SpeedByWeekday/>
			<Altitude/>
		</div>
	);
}

export default React.memo(MyComponent);