import React from 'react';

export default class ProgressBar extends React.Component{

	constructor(){
		super();
		this.state		=	{
			percent	:	0,
        };
	}

	updateProgressBar(){
		const now		=	new Date();
		const percent	=	Math.round( (now.getSeconds() % 10 +1) / 10  * 100 );	//	The +1 is because modulus will effectively never reach 100%; it's just how modulus works.
	//	console.log('Updating Progress Bar:', percent);
		this.setState({percent});
	}

	componentDidMount(){
		if( typeof window != 'undefined' ){
			this.interval	=	setInterval(this.updateProgressBar.bind(this),1000);
		}
	}

	componentWillUnmount(){
		clearInterval(this.interval);
	}

	render(){

		const styles	=	{
			wrapper	:	{
				backgroundColor: 'RGBa(0,0,0,0.1)',
				height: '4px',
				position:   'absolute',
					top:    '0',
					left:   '0',
					right:  '0',
				width:  'auto',
				zIndex:    '1',
			},
			progress:	{
				backgroundColor:   'CornflowerBlue',
				height: '100%',
				margin: 'auto',
				width: `${this.state.percent}%` || '0%',
				transition: 'all ease-in-out 1s',
			},
		};

		return (
			<div className="progress_bar" style={styles.wrapper}><div style={styles.progress}></div></div>
		);

	}
}
