import {
//	distanceBetweenPoints,
	speedInPath,
	distanceTraveledInPath,
	makeGoogleCoordinate,
	prepareDataAsPathArray,
	locationSegmenter,
} from '../Helpers';
import { DateLocalizer } from '../Helpers/DateLocalizer';
import './styles.css';

export class Decorator{

	api_url			=	'https://api.findaidan.com/';
	center_coordinate;
	conversions		=	{
		MetersSecToMilesHr	:	2.23693629,
		MetersToMiles		:	0.000621371,
	};
	default_center	= {
		lat	:	37.335326,
		lng	:	-122.033244,
	};
	existing_objects=	{};
	interval;
	path_data		=	{};
	stroke_colors	=	{
		within_1_hour	:	'Red',
		within_8_hours	:	'DodgerBlue',
		within_16_hours	:	'Lime',
		older			:	'Yellow'
	};

	destruct(){
		//	Destroy things, paths maybe.
	}

	decorate(map){
		this.map		=	map;
		this.decorationTick();	//	Do the first load.
		this.interval	=	setInterval( this.decorationTick.bind(this),10*1000 );	//	Do the rest
	}

	async decorationTick(){
		const {locations}	=	await this.fetchData();
		this.createPaths(locations);
	}

	getExistingObjectsForIndex(index){
		//	If the index is not set, set it.
		//	This is useful for when objects are checked-for before they exist. This will prevent a cannot read property 'xxx' of undefined.
		if( !this.existing_objects[index] )	this.existing_objects[index]	=	{}

		//	Readability
		const existing_objects	=	this.existing_objects[index];

		//	Return
		return {
			path		:	existing_objects.path,
			marker		:	existing_objects.marker,
			infoWindow	:	existing_objects.infoWindow,
		};
	}

	//	@return	Promise
	fetchData(){
	//	console.log('Fetching locations');
		return window.fetch(`${this.api_url}locations/`).then(response=>response.json());
	}

	stopAndGiveLastLocation(){
		window.clearInterval( this.interval );
		const _10_years			=	1000*60*60*24*365*10;	//	Milliseconds
		const datetime_string	=	new Date(Date.now()-_10_years).toJSON();
		return window.fetch(`${this.api_url}locations/?limit=1&created_at_min=${datetime_string}`)
			.then(response=>response.json())
			.then(response=>{
				if( !response.locations.length ){
					throw new Error("🤷🏼‍♂️ We're sorry. We can't find his last location.");
				}
				const location			=	response.locations.pop();
				const index				=	'last_location';	//	Needed for referencing object later... if we need to.
				this.path_data[index]	=	[location];			//	Needed for InfoWindow rendering
				this.map.setCenter( makeGoogleCoordinate(location) );
				const {marker,infoWindow}	=	this.createMarker({location,index});
				infoWindow.open(this.map,marker)
				alert("It's been a while. Maybe his App is off or he just hasn't moved.\r\n\r\nHere's his last location.");
			})
			/*
			.catch(function(error){
				alert(error);
			})
			*/
		;
	}

	createPaths(locations=[]){
	//	console.log('Creating Paths');

		//	---------
		//	Fail-safe
		//	---------
		if( !locations.length ){
			return this.stopAndGiveLastLocation();
		}

		//	-----------
		//	Set Path(s)
		//	-----------
		//	FYI:	center_coordinate is the most recent location returned by the API; period.
		const {path_data,center_coordinate}	=	locationSegmenter(locations);
		this.path_data	=	path_data;

		//	Proceed
		let {marker,infoWindow}	=	this.createMarker({location:locations[0],index:'most_recent'});
		//	------------
		//	Do this ONCE
		//	------------
		if( !this.center_coordinate ){	//	If we don't have this set yet.
			console.log('Centering on Coordinate:',center_coordinate);
			this.map.setCenter(center_coordinate);
			infoWindow.open(this.map,marker);	//	Show best 'current location' infoWindow on load.
			//	Keep track of these
			this.existing_objects.most_recent	=	{
				marker,
				infoWindow,
			};
		}//if

		//	--------------
		//	Do this always
		//	--------------
		marker.setPosition( makeGoogleCoordinate(locations[0]) );
		this.center_coordinate	=	center_coordinate;	//	Keep track for next iteration.

		//	.reverse so newest lines are pained last; so newest lines are not painted-over by older ones.
		Object.entries(path_data).reverse().forEach((entry)=>{
			const [index,locations]		=	entry;

			//	If existing
			let {path,marker,infoWindow}	=	this.getExistingObjectsForIndex(index);
			if( path instanceof window.google.maps.Polyline ){
				path.setPath( prepareDataAsPathArray(locations) );
			}else{
			//	If we need to make it
				path = new window.google.maps.Polyline({
					path			:	prepareDataAsPathArray(locations),	//	Can also be set this later with .setPath(Array)
					geodesic		:	true,
					strokeColor		:	this.stroke_colors[index] ?? 'LimeGreen',
					strokeOpacity	:	1,
					strokeWeight	:	6,
				});
				path.setMap( this.map );
				this.existing_objects[index].path	=	path;
			}//ifelse

			//	Marker Icon
			//	InfoWindow (opened 'onclick' of its Marker)
			const objects	=	this.createMarker({location:locations[0],index});

		});

	}

	createMarker({location,index}){
		//	Breakpoint
		if( !location ){
		//	console.log('No location passed for path index:',index);
			return {marker:null,infoWindow:null};
		}

		//	--------
		//	Map icon
		//	--------
		let icon;
		if( 0 < location.speed )	icon	=	'/images/motorcycle.png';

		//	-------------
		//	Marker Object
		//	-------------
		let {path,marker,infoWindow}	=	this.getExistingObjectsForIndex(index);
		//	If existing
		if( marker instanceof window.google.maps.Marker ){
			marker.setPosition(makeGoogleCoordinate(location));
		}else{
		//	If we need to make it
			marker	=	new window.google.maps.Marker({
				map			:	this.map,
				position	:	makeGoogleCoordinate(location),
				title		:	index,
				icon		:	icon,	//	Must be a {String}
			});
			this.existing_objects[index].marker	=	marker;
		}//ifelse

		//	--------
		//	Children
		//	--------
		infoWindow	=	this.createInfoWindow({location,index});

		return {marker,infoWindow};
	}

	createInfoWindow({location,index}){

		const html	=	this.generateInfoWindowHTML(location,index);

		let {path,marker,infoWindow}	=	this.getExistingObjectsForIndex(index);

		//	If existing
		if( infoWindow instanceof window.google.maps.InfoWindow ){
			infoWindow.setContent(html);
		}else{
		//	If we need to make it
			infoWindow	=	new window.google.maps.InfoWindow({
				content	:	html,
			});
			this.existing_objects[index].infoWindow	=	infoWindow;
			marker.addListener('click', function(){
				infoWindow.open(this.map,marker);
			});
		}//ifelse

		return infoWindow;
	}
	generateInfoWindowHTML(location,index){

		//	Speed
		const miles_hour	=	location.speed * this.conversions.MetersSecToMilesHr;


		//	Time
		const at_date		=	new Date(location.timestamp);	//	Timestamp uses 'Z' so this date will already have an accurate timezone.
		const at_localtime	=	DateLocalizer.toLocalString(at_date);


		//	Path
		const locations			=	this.path_data[index];
		let distance_traveled	=	0;
		let speed_average		=	0;
		let speed_max			=	0;
		let duration			=	0;
		if( locations instanceof Array ){
			//	Distance
			distance_traveled	=	Math.round( distanceTraveledInPath(locations) * this.conversions.MetersToMiles * 100 )/100;
			//	Speed
			const {average,max}	=	speedInPath(locations);
			speed_average	=	Math.round( average * this.conversions.MetersSecToMilesHr);
			speed_max		=	Math.round( max * this.conversions.MetersSecToMilesHr);
			//	Time
			const since_date	=	new Date(locations[locations.length-1].timestamp);	//	Timestamp uses 'Z' so this date will already have an accurate timezone.
			duration		=	( at_date.getTime() - since_date.getTime() )/1000;	//	Seconds elapsed
		}

		//	HTML
		const strings	=	{
			label	:	location.label ? (<strong><font color="DodgerBlue" size="3">{location.label}</font></strong>) : '' ,
			speed	:	0 < Number(location.speed) ? `<li>${getSpeedIcon(location.speed)}<strong>${Math.round(miles_hour)}</strong> Mi/Hr</li>` : '' ,
			heading	:	0 < Number(location.heading) ? `<li>${getHeadingArrow(location.heading)} <span class="text_muted">${Math.round(location.heading)}&deg;</span></li>` : '' ,
			distance:	0 < distance_traveled ? `<li class="text_muted">${distance_traveled} miles</li>` : '' ,
			duration:	duration ? `<li class="text_muted">Duration: ${timeIntervalToString(duration)}</li>` : '' ,
			speed_in_path:	speed_average && speed_max ? `<li class="text_muted">${speed_average} avg, ${speed_max} max (mph)</li>` : '' ,
			pill	:	['last_location','most_recent'].indexOf(index) != -1 ? ' 👣' : `<span class="pill" style="background-color:${getStrokeColor.call(this,index)};"></span>` ,
		};

		function pathDetails(){
			//	Breakpoint
			if(
				!strings.distance &&
				!strings.speed_in_path &&
				!strings.duration
			){
				return '';
			}

			//	Content
			return `
				<hr/>
				${ strings.distance }
				${ strings.speed_in_path }
				${ strings.duration }
			`;
		}

		//	Render
		return `
			<div class="map_marker_info">
				${strings.label}
				<ul>
					<li><strong>${getTimeElapsedString(at_date)}</strong>${strings.pill}</li>
					${ strings.speed }
					<li class="text_muted">${at_localtime}</li>
					${ strings.heading }
					<li class="text_muted">${location.latitude}, ${location.longitude}</li>
					${ pathDetails() }
				</ul>
			</div>
		`.trim();

		//	-------
		//	Hoisted
		//	-------
		//	@param	heading	{Number}	in degreees
		function getHeadingArrow(degrees){
			let t	=	22.5;	//	tolerance
			let icon;
			if( 360-t < degrees || degrees <= 0+t )		icon	=	'<strong>↑</strong> N';
			if(  45-t < degrees && degrees <= 45+t )	icon	=	'<strong>↗</strong> NE';
			if(  90-t < degrees && degrees <= 90+t )	icon	=	'<strong>→</strong> E';
			if( 135-t < degrees && degrees <= 135+t )	icon	=	'<strong>↘</strong> SE';
			if( 180-t < degrees && degrees <= 180+t )	icon	=	'<strong>↓</strong> S';
			if( 225-t < degrees && degrees <= 225+t )	icon	=	'<strong>↙</strong> SW';
			if( 270-t < degrees && degrees <= 270+t )	icon	=	'<strong>←</strong> W';
			if( 315-t < degrees && degrees <= 315+t )	icon	=	'<strong>↖</strong> NW';
			return icon;
		}

		//	@param	speed	{Number}	Meters per second
		function getSpeedIcon(speed){
			let speed_icon	=	'';
			if( 1 < location.speed )	speed_icon	=	'🚶‍♂️';
			if( 2 < location.speed )	speed_icon	=	'🏃‍♂️';
			if( 5 < location.speed )	speed_icon	=	'🚙';
			if( 50 < location.speed )	speed_icon	=	'✈️';
			if( speed_icon )	speed_icon	+=	' ';	//	Add some spacing
			return speed_icon;
		}

		//	@param	date	{Date Object}
		function getTimeElapsedString(date){

			const time_elapsed	=	( new Date().getTime() - date.getTime() )/1000;	//	To Seconds

			return timeIntervalToString(time_elapsed) + ' ago';
		}

		//	@param	seconds	{Integer}
		function timeIntervalToString(seconds){

			const time_elapsed	=	seconds;	//	To Seconds

			const daysago		=	Math.round( (time_elapsed/60/60/24)	* 10) / 10;
			const hoursago		=	Math.round( (time_elapsed/60/60)	* 10) / 10;
			const minutesago	=	Math.round( (time_elapsed/60)		);
			const secondsago	=	Math.round( (time_elapsed)			);

			let time_string;
			if( 1 < secondsago )
				time_string	=	`${secondsago} second${secondsago==1?'':'s'}`;
			if( 1 < minutesago )
				time_string	=	`${minutesago} minute${minutesago==1?'':'s'}`;
			if( 1 < hoursago )
				time_string	=	`${hoursago} hour${hoursago==1?'':'s'}`;
			if( 1 < daysago )
				time_string	=	`${daysago} day${daysago==1?'':'s'}`;

			return time_string;
		}


		function getStrokeColor(index){
			return this.stroke_colors[index] ?? 'LimeGreen' ;
		}

	}//function generateInfoWindowHTML()

}
