import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import Map from './components/Map';
import Metrics from './components/Metrics';

ReactDOM.render(
  <React.StrictMode>
    <div className="App">
      <Map/>
      <Metrics/>
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
