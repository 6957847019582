import React from 'react'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import {Decorator} from './Decorator';
import ProgressBar from './ProgressBar';
//	import location_array from './test_data.json';

const D	=	new Decorator();

function MyComponent() {

	const styles_map_night_mode	=	[
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }],
        },
      ];

	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: 'AIzaSyAGqFDd73mWxo0bSI2OVDQXweMNRs7j79Q',
	});

	const [map, setMap]				=	React.useState(null);

	const onLoad = React.useCallback(function callback(map) {
	//	const bounds = new window.google.maps.LatLngBounds();
	//	map.fitBounds(bounds);
	//	map.setOptions({});

		setMap(map);
		D.decorate(map);
	}, []);

	const onUnmount = React.useCallback(function callback(map) {
		D.destruct();
		setMap(null)
	}, [])

	//	----------
	//	Breakpoint
	//	----------
	if( !isLoaded ){
		return (<></>);
	}

	//	--------------
	//	Debugging Only
	//	--------------
//	console.log( isLoaded ? window.google : 'Nothing' );

	//	------
	//	Render
	//	------
	function isDayTime(){
		const now	=	new Date();
		return ( 6<now.getHours() && now.getHours()<19 );
	}
	const containerStyle = {
	//	width	:	'100%',
		height	:	'100vh',
	};
	return (
      <div>
		<ProgressBar/>
        <GoogleMap
            mapContainerStyle={containerStyle}
			deprecated_center={D.default_center}
            zoom={12}
			options={{
				fullscreenControl	:	false,
				mapTypeId			:	window.google.maps.MapTypeId.ROADMAP,	//	Others are: HYBRID, ROADMAP, SATELLITE, TERRAIN
				mapTypeControl		:	false,
				rotateControl		:	false,
				scaleControl		:	false,
				streetViewControl	:	false,
				zoomControl			:	false,
				styles				:	isDayTime() ? {} : styles_map_night_mode ,
			}}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            { /* Child components, such as markers, info windows, etc. */ }
            <></>
        </GoogleMap>
      </div>
  );
}

export default React.memo(MyComponent);