export function prepareDataAsPathArray(locations){
	return locations.map(function(location){
		return makeGoogleCoordinate(location);
	});
};


export function locationSegmenter(locations=[]){

	//	Breakpoint
	if( !locations.length )	return {path_data:[],center_coordinate:null};

	//	Proceed
	const center_coordinate	=	makeGoogleCoordinate(locations[0]);

//	console.log('CENTER:',locations[0]);	//	Debugging Only

	const _16_hours_ago	=	Date.now() - 16*60*60*1000;
	const _8_hours_ago	=	Date.now() - 8*60*60*1000;
	const _1_hour_ago	=	Date.now() - 1*60*60*1000;

	//	Put locations into bins
	const path_data	=	{
		within_1_hour	:	[],
		within_8_hours	:	[],
		within_16_hours	:	[],
		older			:	[],
	};

	locations.forEach(function(location){

		let location_timestamp	=	new Date(location.timestamp).getTime();

		let time_category	=	'older';
		if( location_timestamp > _16_hours_ago )
			time_category	=	'within_16_hours';
		if( location_timestamp > _8_hours_ago )
			time_category	=	'within_8_hours';
		if( location_timestamp > _1_hour_ago )
			time_category	=	'within_1_hour';

		path_data[time_category].push(location);

	});

	return {path_data,center_coordinate};

}


export function makeGoogleCoordinate(location){
	return {
		lat	:	Number( location.latitude ),
		lng	:	Number( location.longitude ),
	};
}


//	Vincenty Formula for Great-Circle Distance
//	http://stackoverflow.com/questions/10053358/measuring-the-distance-between-two-coordinates-in-php
//	@param	locations					{Object}
//	@param	locations.loc1				{Object}	Coordinate#1
//	@param	locations.loc1.latitude		{Number}
//	@param	locations.loc1.longitude	{Number}
//	@param	locations.loc2				{Object}	Coordinate#1
//	@param	locations.loc2.latitude		{Number}
//	@param	locations.loc2.longitude	{Number}
//	@return	meters						{Number}
export function distanceBetweenPoints({loc1,loc2}){
	const earth_radius	=	6371000;	//	In Meters

	// convert from degrees to radians
	const $latFrom	=	degreesToRadians(loc1.latitude);
	const $lonFrom	=	degreesToRadians(loc1.longitude);
	const $latTo	=	degreesToRadians(loc2.latitude);
	const $lonTo	=	degreesToRadians(loc2.longitude);

	const lonDelta = $lonTo - $lonFrom;
	const a = (
		Math.pow(Math.cos($latTo) * Math.sin(lonDelta), 2) +
		Math.pow(Math.cos($latFrom) * Math.sin($latTo) - Math.sin($latFrom) * Math.cos($latTo) * Math.cos(lonDelta), 2)
	);
	const b = Math.sin($latFrom) * Math.sin($latTo) + Math.cos($latFrom) * Math.cos($latTo) * Math.cos(lonDelta);

	const angle = Math.atan2(Math.sqrt(a), b);
	return angle * earth_radius;

	//	Hoisted
	function degreesToRadians(degrees){
		return degrees * (Math.PI/180);
	}

};


//	@param	locations	{Array<Locations>}
//	@return meters		{Number}
export function distanceTraveledInPath(locations=[]){
	const distance_traveled		=	locations.reduce(function(accumulator,location,index,array){
		//	Break before the end of array because we're looking ahead; it'll be null.
		if( index == array.length - 1 )	return accumulator;
		//	Proceed
		const loc1	=	location;
		const loc2	=	array[index+1];
		return accumulator + distanceBetweenPoints({loc1,loc2});
	},0);
	return distance_traveled;	//	In Meters
};


//	@param	locations	{Array<Locations>}
//	@return Object		{average,max}	//	Meters/Sec
export function speedInPath(locations=[]){
	let max					=	0;
	const nonnull_speeds	=	locations.filter(_l=>0<=_l.speed)
	const numerator			=	nonnull_speeds.reduce(function(accumulator,location){
		//	Max Speed
		if( max < location.speed )	max = location.speed;
		//	Average Speed
		return accumulator + location.speed;
	},0);
	const average			=	numerator / nonnull_speeds.length;
	return {
		average	:	average,	//	In Meters / Sec
		max		:	max,
	};
};
