import React from 'react';
const { Chart } = require('react-google-charts');

const MetersSecToMilesHr	=	2.23693629;
const MetersToFeet			=	3.28084;

function MyComponent() {

	const api_url					=	'https://api.findaidan.com/';

	const [error, setError]			=	React.useState(null);
	const [isLoaded, setIsLoaded]	=	React.useState(false);
	const [metric, setMetric]		=	React.useState([]);

	React.useEffect(function(){
		window.fetch(`${api_url}metrics/speedaltitude_by_dayhourminute`)
			.then(response=>response.json())
			.then(function(response){
				setIsLoaded(true);
				setMetric(response.metric);
			},function(error){
				setIsLoaded(true);
				setError(error);
			})
		;
	},[]);

	//	---------
	//	Fail-safe
	//	---------
	if( error || !isLoaded || !metric ){
		return (
			<div style={{backgroundColor:'#eee'}}>
				<h1>⏳ Loading...</h1>
			</div>
		);
	}


	//	-----------------------------------
	//	Load API data into chart data array
	//	-----------------------------------
	const data	=	[];
	metric.forEach(function(row){
		const speed	=	row.speed_max<0 ? 0 : Number(row.speed_max) * MetersSecToMilesHr ;
		data.push([
			new Date(row.timestamp),
			speed,
			Number(row.altitude_average) * MetersToFeet,
		]);
	});

	//	-----------------
	//	Insert Header Row
	//	-----------------
	data.splice(0,0,[
		{type:'datetime', label:'Time'},
		'Speed (mph)',
		'Altitude (ft)',
	]);

	//	-------
	//	Options
	//	-------
	const options_material=	{
		chart: {
			title: 'Altitude/Speed by Time (24 hours)',
			subtitle: 'Speed & Altitude'
		},
		legend: {
			position: 'none',	//	'none' is valid (but 'top'|'bottom' are not when using 'Material UI' version of chart)
		},
		series: {
			0: { axis: 'Speed' },
			1: { axis: 'Altitude' },
		},
		axes: {
			// Adds labels to each axis; they don't have to match the axis names.
			y: {
				Speed: { label: 'Speed (mph)' },
				Altitude: { label: 'Altitude (ft)' },
			},
		},
	};

	const timestamp_yesterday	=	Date.now()-1000*60*60*24;
	const options_classic=	{
		title: 'Altitude/Speed by Time (24 hours)',
		legend: {
			position: 'top',	//	'none' is valid
		},
		series: {
			0: { targetAxisIndex: 0,curveType: 'function'},
			1: { targetAxisIndex: 1,curveType: 'function' },
		},
		vAxes: {
			0:	{ title: 'Speed (mph)' },
			1:	{ title: 'Altitude (ft)' },
		},
		hAxis: {
			minValue: new Date(timestamp_yesterday),
			maxValue: new Date(),
			format: 'h a',
		},
	};

	return (
		<div>
			<Chart
				width={'100%'}
				height={'400px'}
				chartType="LineChart"
				loader={<div>Loading Chart</div>}
				data={data}
				options={options_classic}
				rootProps={{ 'data-testid': '2' }}
			/>
		</div>
	);
}

export default React.memo(MyComponent);