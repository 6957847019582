import React from 'react';
const { Chart } = require('react-google-charts');

const MetersSecToMilesHr	=	2.23693629;

function MyComponent() {

	const api_url					=	'https://api.findaidan.com/';

	const [error, setError]			=	React.useState(null);
	const [isLoaded, setIsLoaded]	=	React.useState(false);
	const [metric, setMetric]		=	React.useState([]);

	React.useEffect(function(){
		window.fetch(`${api_url}metrics/speed_by_hour`)
			.then(response=>response.json())
			.then(function(response){
				setIsLoaded(true);
				setMetric(response.metric);
			},function(error){
				setIsLoaded(true);
				setError(error);
			})
		;
	},[]);

	//	---------
	//	Fail-safe
	//	---------
	if( error || !isLoaded || !metric ){
		return (
			<div style={{backgroundColor:'#eee'}}>
				<h1>⏳ Loading...</h1>
			</div>
		);
	}

	//	------
	//	Render
	//	------
	const date	=	new Date();	//	Used in array mapping|sorting

	const data	=	[];
	//	Headers
	data.push([
		{ type: 'timeofday', label: 'Hour' },
		'Max',
		'Average',
	]);
	//	Data
	/*
	metric.map(function(row){
		//	Convert to Local Timezone & attach data.
		date.setUTCHours(row.hour);
		row.hourslocal	=	date.getHours();
		row.ampm		=	date.toLocaleString('en-US', { hour: 'numeric', hour12: true });
		return row;
	})
	.sort(function(a,b){
		//	Sort by localtime
		if( a.hourslocal < b.hourslocal )	return -1;
		if( a.hourslocal > b.hourslocal )	return 1;
	})
	*/
	metric.forEach(function(row){
		//	Set for rendering
		data.push([
			toGoogleTimeOfDay( new Date(row.timestamp) ),
			Number(row.speed_max) * MetersSecToMilesHr,
			Number(row.speed_average) * MetersSecToMilesHr,
		]);
	});

	//	Reference:
	//		https://developers.google.com/chart/interactive/docs/datesandtimes#working-with-timeofday
	function toGoogleTimeOfDay(date){
		return [
			date.getHours(),
			0,
			0
		];
	}

///	console.log(data);

//	Reference:
//		https://developers.google.com/chart/interactive/docs/gallery/columnchart

	const timestamp_yesterday	=	Date.now()-1000*60*60*24;
	return (
		<div>
			<Chart
				width={'100%'}
				height={300}
				chartType="ColumnChart"
				loader={<div>Loading Chart</div>}
				data={data}
				options={{
					title: 'Speed by Hour (24-hours)',
				//	bar:{groupWidth: '100%',},	//	Makes fat bars but not if there are too many datapoints on the hAxis.
				//	chartArea: { width: '30%' },
					hAxis: {
						minValue: new Date(timestamp_yesterday),
						maxValue: new Date(),
						format: 'h a',
					},
					vAxis: {
						title: 'miles/hr',
					//	viewWindowMode:'explicit',
						viewWindow: {
						//	max:	100,
							min:	0,
						},
					},
					legend: {
						position: 'top',	//	'none' is valid
					},
				}}
				legendToggle
			/>
		</div>
	);
}

export default React.memo(MyComponent);