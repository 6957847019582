export class DateLocalizer{

	static months	=	[
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	static toLocalString(date){
		const string	=	[];
			string.push( DateLocalizer.months[date.getMonth()] );
			string.push( addSuffixToNumber(date.getDate())+',' );
			string.push( getTimeAs12Hour(date) );

		return string.join(' ');
	}

}//class

//	-------------------
//	Support Function(s)
//	-------------------
/*
function addSuffixToNumber(number){
	//	Truncate number
	number = Math.floor(number);
	//	Get last digit
	var significant_number = number.toString()[number.toString().length-1];
	if(significant_number == 1)	return number+'st';
	if(significant_number == 2)	return number+'nd';
	if(significant_number == 3)	return number+'rd';
	return number+'th';
}
*/
function addSuffixToNumber(number,exclude_number_with_suffix){
	var suffixes	=	['th','st','nd','rd','th','th','th','th','th','th'];
	var string		=	'';

	if( number%100 >= 11 && number%100 <= 13 )
		string	=	'th';
	else
		string = suffixes[number%10];

	if( !exclude_number_with_suffix )
		string	=	number + string;

	return	string;
}//function

function padLeftZeros(number){
	if(number < 10)
		return '0'+number;
	return number;
}

function getTimeAs12Hour(date){
	let hours;
	let ampm;
	hours	=	12 < date.getHours() ? date.getHours()-12 : date.getHours() ;
	if( hours==0 )	hours = 12;
	ampm	=	11 < date.getHours() ? 'pm' : 'am' ;

	return hours+':'+padLeftZeros(date.getMinutes())+ampm;
}