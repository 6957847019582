import React from 'react';
const { Chart } = require('react-google-charts');

const MetersSecToMilesHr	=	2.23693629;

function MyComponent() {

	const api_url					=	'https://api.findaidan.com/';

	const [error, setError]			=	React.useState(null);
	const [isLoaded, setIsLoaded]	=	React.useState(false);
	const [metric, setMetric]		=	React.useState([]);

	React.useEffect(function(){
		window.fetch(`${api_url}metrics/speed_by_weekday`)
			.then(response=>response.json())
			.then(function(response){
				setIsLoaded(true);
				setMetric(response.metric);
			},function(error){
				setIsLoaded(true);
				setError(error);
			})
		;
	},[]);

	//	---------
	//	Fail-safe
	//	---------
	if( error || !isLoaded || !metric ){
		return (
			<div style={{backgroundColor:'#eee'}}>
				<h1>⏳ Loading...</h1>
			</div>
		);
	}

	//	------
	//	Render
	//	------
//	console.log('Marker 1',metric);
	const weekday_names	=	{
		'0'	:	'Sunday',
		'1'	:	'Monday',
		'2'	:	'Tuesday',
		'3'	:	'Wednesday',
		'4'	:	'Thursday',
		'5'	:	'Friday',
		'6'	:	'Saturday',
	};

	const data	=	[];
	//	-------------
	//	Preload Empty data so no weekdays are left blank
	//	-------------
//	console.log( Object.entries(weekday_names) );
	Object.entries(weekday_names).forEach(function(entry){
	//	console.log(entry);
		const [index,weekday]	=	entry;
		data[index]				=	[
			weekday,
			0,
			false,
			'',
			0,
			false
		];
	});

	//	---------
	//	Load Data
	//	---------
	const date	=	new Date();
	metric.forEach(function(row){
		let data_point_is_in_scope	=	false;
		if( row.weekday<=date.getDay() )
			data_point_is_in_scope	=	true;
		const speed_average	=	row.speed_average<0	? 0 : Number(row.speed_average) * MetersSecToMilesHr ;
		const speed_max		=	row.speed_max<0		? 0 : Number(row.speed_max) * MetersSecToMilesHr ;
		data[row.weekday] = [
			weekday_names[row.weekday],
			speed_max,
			data_point_is_in_scope,
			data_point_is_in_scope ? '' : 'Last week',
			speed_average,
			data_point_is_in_scope,
		];
	});

	//	-----------------
	//	Insert Header Row
	//	-----------------
	data.splice(0,0,[
		'Weekday',
		'Max',
		{ type:'boolean',role: 'scope' },
		{type:'string', role:'annotation'},
		'Average',
		{ type:'boolean',role: 'scope' },
	]);


//	console.log(data);

//	Reference:
//		https://developers.google.com/chart/interactive/docs/gallery/columnchart

	return (
		<div>
			<Chart
				width={'100%'}
				height={300}
				chartType="ColumnChart"
				loader={<div>Loading Chart</div>}
				data={data}
				options={{
					title: 'Speed by weekday (7-day)',
				//	chartArea: { width: '30%' },
					hAxis: {
					//	title: 'Weekday',
					//	minValue: 0,
					},
					vAxis: {
						title: 'miles/hr',
					},
					legend: {
						position: 'top',	//	'none' is valid
					},
				}}
				legendToggle
			/>
		</div>
	);
}

export default React.memo(MyComponent);