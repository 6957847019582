import React from 'react';
import Charts from './Charts';

function MyComponent() {

	const [showing,setShowing]	=	React.useState(false);

	function onclick(){
		setShowing(!showing);
	}

	return (
		<div>
			<div className="Charts__toggle" style={{
				position	:	'absolute',
				top			:	'10px',
				right		:	'20px',
			}} onClick={onclick}><span>📊</span></div>
			{showing?<Charts/>:<></>}
		</div>
	);
}

export default React.memo(MyComponent);
